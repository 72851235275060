import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ConfigService from "../../services/config-service";
import { Dashboard } from "@mui/icons-material";
import ContactService from "../../services/contact-service";
import { toast } from "react-toastify";

export default function DashboardDialog() {
  const [open, setOpen] = React.useState(false);
  const [max, setMax] = useState({});
  const [nbCoach, setNbCoach] = useState([]);
  const [total, setTotal] = useState(0);
  const [ncAttr, setNcAttr] = useState(0);
  const [doUpdate, setDoUpdate] = useState(true);

  useEffect(() => {
    if (!doUpdate) {
      return;
    }
    ConfigService.getNbNcMax().then((data) => {
      setMax(data[0]);
      setTotal(data[0].value);
    });
    if (nbCoach && nbCoach.length > 0) {
      let max = 0;
      for (let x of nbCoach) {
        max += Number(x.total_suivi);
      }
      setTotal(max);
    }
    setDoUpdate(false);
  }, [doUpdate, nbCoach]); // Only re-run the effect if nbCoach changes

  const handleClickOpen = () => {
    ConfigService.getNcByCoach().then((data) => setNbCoach(data));
    ContactService.list().then((data) => setNcAttr(data.data.length));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    ConfigService.updateNbNcMax(max.id, {
      value: total,
      name: "quota_suivi_contact",
    })
      .then((response) => {
        setDoUpdate(true);
        toast.success(response.data.description, { autoClose: 2000 });
      })
      .catch((error) => {
        toast.error(error.response.data.description);
      });
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        startIcon={<Dashboard />}
        color="info"
        onClick={handleClickOpen}
      >
        Tableau de bord
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-31-primary">
          {"Tableau de bord des attributions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={"div"}>
            <form className="row g-3 mb-2">
              <div className="col-auto">
                <label className="visually-hidden mt-2">
                  Capacité maximale:
                </label>
                <span className="badge badge-primary rounded-pill">
                  {max.value}
                </span>
              </div>
              <div className="col-auto">
                <input
                  type="number"
                  name="total"
                  id=""
                  min={1}
                  max={20}
                  className="form-control"
                  onChange={(e) => setTotal(e.target.value)}
                  value={total}
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-secondary" onClick={handleUpdate}>
                  Modifier
                </button>
              </div>
            </form>
            <div className="row">
              <div className="col-auto">
                <label className="visually-hidden mt-2">
                  Capacité totale disponible
                </label>
              </div>
              <div className="col-auto">
                <span className="badge badge-warning rounded-pill">
                  {nbCoach.length * max.value - total}{" "}
                </span>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-auto">
                <label className="visually-hidden mt-2">
                  NC en attente d'attribution
                </label>
              </div>
              <div className="col-auto">
                <span className="badge badge-warning rounded-pill">
                  {ncAttr}
                </span>
              </div>
            </div>
            <table className="table table-striped table-bordered text-center table-sm">
              <thead>
                <tr>
                  <th>
                    <small>Coach</small>
                  </th>
                  <th>
                    <small>Capacité disponible</small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nbCoach.map((coach) => {
                  return (
                    <tr key={coach.id}>
                      <td className="text-left">
                        <small>{coach.name}</small>
                      </td>
                      <td>
                        <small>{Math.max(max.value - coach.total_suivi, 0)}</small>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="text-danger">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { useStore } from "react-redux";

import ContactService from "../../services/contact-service";
import ConfigService from "../../services/config-service";
import SuiviContactService from "../../services/suivi-contacts-service";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row } from "react-bootstrap";

import DashboardDialog from "./dialog-dashboard";
import moment from "moment";


const AttributionCoach = () => {
  const [listeCoaches, setListeCoaches] = useState([]);
  const [coach, setCoach] = useState({});
  const [doUpdate, setDoUpdate] = useState(true);
  const [totalByCoach, setTotalByCoach] = useState([]);
  const [listeAttributions, setAttribution] = useState([]);
  const [contacts, setContacts] = useState([]);
  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);

  const coachListRef = useRef();

  const handleSelectCoach = (e) => {
    e.preventDefault();
    let selectedCoach = listeCoaches.find(
      (el) => parseInt(el.id) === parseInt(coachListRef.current.value)
    );
    setCoach(selectedCoach);
  };

  const handleAddContact = (contact, e) => {
    e.persist();
    if (Object.keys(coach).length === 0) {
      toast.error("Vous devez selectionner un coach");
      e.target.checked = false;
      return;
    }

    if (totalByCoach - coach.total_suivi <= 0) {
      toast.error("Ce coach ne peut plus recevoir de nouveaux NC");
      e.target.checked = false;
      return;
    }

    let addCoach = coach
    addCoach.total_suivi += 1

    contact.status = "En attente"
    contact.checked = true
    contact.coach = addCoach
    contact.showRemove = true;

    setCoach(addCoach)
    setAttribution((previousListe) => [...previousListe, contact])

    const prevListe = listeCoaches.filter((c) => c.id !== addCoach.id)
    setListeCoaches([...prevListe, addCoach])

    setContacts((liste) => liste.filter((c) => c.id !== contact.id));
  }

  const handleRemoveContact = (contact, e) => {
    contact.checked = false;
    contact.status = "En attente";

    let removeCoach = listeCoaches.find((c) => c.id === contact.coach.id)
    removeCoach.total_suivi -= 1
    const prevListe = listeCoaches.filter((c) => c.id !== removeCoach.id)
    setListeCoaches([...prevListe, removeCoach])
    if (contact.coach.id === coach.id) {
      setCoach({ ...coach, total_suivi: removeCoach.total_suivi })
    }
    contact.coach = null

    setAttribution((previousListe) =>
      previousListe.filter((c) => c.id !== contact.id)
    );
    setContacts([...contacts, contact]);
  }

  const handleAffectationClick = (e) => {
    e.preventDefault();

    listeAttributions.map((contact) => {
      if (contact.status !== "Succès") {
        SuiviContactService.add({
          contact_id: contact.id,
          coach_id: coach.id,
          date_debut: moment().format("yyyy-MM-DD"),
          date_fin: null,
          commentaires: "",
        })
          .then((response) => {
            contact.status = "Succès";
            contact.showRemove = false;
            contact.description = response.data.description
          })
          .catch((error) => {
            contact.status = "Echec";
            if (error.code === "ERR_BAD_REQUEST") {
              let contact_error =
                error.response.data.errors?.contact_id === undefined
                  ? ""
                  : error.response.data.errors?.contact_id[0];
              let coach_error =
                error.response.data.errors?.coach_id === undefined
                  ? ""
                  : error.response.data.errors?.coach_id[0];
              contact.description = contact_error + " " + coach_error
            }
            else {
              contact.description = error.response.data.description
            }
          });
        setDoUpdate(true);
      }
      return true;
    });
    toast.success("Traitement terminé", { autoClose: 2000 });
  };

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });
    if (doUpdate) {
      setContacts([]);
      ConfigService.getNbNcMax().then((data) => { setTotalByCoach(data[0].value); });
      ConfigService.getNcByCoach().then((data) => setListeCoaches(data));
      ContactService.getListeAttente().then((data) => {
        data.data.map((d) => {
          d.checked = false;
          setContacts((previousData) => [...previousData, d]);
          return true;
        });
      });
    }
    setDoUpdate(false);

  }, [store, doUpdate]);

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header  sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Attribution Coach</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">Gestion des NC</li>
              <li className="breadcrumb-item active">Attribution Coach</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="body">
            <div className="row my-2">
              <div className="col-3">
                <h5 className="text-31-primary">Ajouter des attributions</h5>
              </div>
              <div className=" offset-1 col-3">
                <DashboardDialog />
              </div>
            </div>
            <hr />
            <Row className="mt-4">
              <Col lg={6}>
                <label htmlFor="searchKey">Liste des NC en attente d'attribution</label>
                <table className="table table-striped table-bordered text-center table-sm">
                  <thead>
                    <tr><th>&nbsp;</th>
                      <th><small>Prénom</small></th>
                      <th><small>Nom</small></th>
                      <th><small>Email</small></th>
                      <th><small>Date Inscription</small></th>
                    </tr>
                  </thead>
                  <tbody>
                    {contacts.map(
                      (contact, index) =>
                        contact && (
                          <tr key={contact.id}>
                            <th scope="row">
                              <i className="fa fa-plus" onClick={(e) => handleAddContact(contact, e)}></i>
                            </th>
                            <td><small>{contact.firstname}</small></td>
                            <td><small>{contact.lastname}</small></td>
                            <td><small>{contact.email}</small></td>
                            <td><small>
                              {moment(contact.subscription_date).format(
                                "DD/MM/yyyy"
                              )}
                            </small>
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </Col>
              <Col lg="6">
                <Row>
                  <Col lg="5">
                    <label htmlFor="coach">Sélection du coach</label>
                    <select className="form-control" name="coach" id="coach" defaultValue={""}
                      ref={coachListRef} placeholder="Coach ..." onChange={handleSelectCoach}
                    >
                      {Object.keys(coach).length === 0 && (<option value="" disabled hidden></option>)}
                      {listeCoaches.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.firstname} {item.name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col lg="6" className="pt-4">
                    <label className="mr-3">NC déja attribués : {Object.keys(coach).length !== 0 ? coach.total_suivi : 0}</label>
                    <label>NC restant : {Object.keys(coach).length !== 0 ? Math.max(totalByCoach - coach.total_suivi, 0) : 0}</label>
                  </Col>
                </Row>
                {listeAttributions.length > 0 && (
                  <React.Fragment>
                    <Row className="mt-3">
                      <Col lg={12}>
                        <table className="table table-striped table-bordered text-center table-sm">
                          <tbody>
                            <tr>
                              <th><small>&nbsp;</small></th>
                              <th>Prénom</th>
                              <th>Nom</th>
                              <th>Téléphone</th>
                              <th>Coach</th>
                              <th>Status</th>
                              <th>Description</th>
                            </tr>
                            {listeAttributions.map((contact) => (
                              <tr key={contact.id}>
                                <td>
                                  {contact.showRemove
                                    ? <i className="fa fa-minus" onClick={(e) => handleRemoveContact(contact, e)}></i>
                                    : " "
                                  }
                                </td>
                                <td><small>{contact.firstname}</small></td>
                                <td><small>{contact.lastname}</small></td>
                                <td><small>{contact.telephone}</small></td>
                                <td><small>{contact.coach.firstname} {contact.coach.name}</small></td>
                                <td><small>{contact.status}</small></td>
                                <td><small>{contact.description}</small></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <span className="text-center text-31-primary">
                          <strong>Total des NC sélectionnés pour attribution : {listeAttributions.length}</strong>
                        </span>
                      </Col>
                      <Col lg={4}>
                        <button mt="5" type="button" className="btn btn-outline-primary btn-lg"
                          data-bs-toggle="modal" data-bs-target="#myModal" onClick={handleAffectationClick}>
                          Procéder aux attributions
                        </button>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </Col>
            </Row>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttributionCoach;

import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";

import SuiviContactService from "../../services/suivi-contacts-service";
import ContactService from "../../services/contact-service";
import UserService from "../../services/user-service";
import AffectactionTable from "../../components/layouts/data-table-affectation";
import DashboardDialog from "./dialog-dashboard";

const ListAffectations = () => {
  const initialState = [];
  const [affectations, setAffectations] = useState(initialState);
  const [contacts, setContacts] = useState(initialState);
  const [users, setUsers] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (doUpdate) {
      SuiviContactService.list().then((datas) => {
        const affectationItems = datas.data.map((a) => ({
          ...{
            id: a.id,
            contact_id: a.contact_id,
            coach: a.coach && a.coach.name + " " + a.coach.firstname,
            contact_name:
              a.contact && a.contact.firstname + " " + a.contact.lastname,
            coach_id: a.coach_id,
            date_debut: a.date_debut,
            date_fin: a.date_fin,
            is_termine: a.is_termine === "1",
            do_termine: a.is_termine === "0",
            commentaires: a.commentaires,
          },
        }));
        setAffectations(affectationItems);
        setDoUpdate(false);
      });
    }

    ContactService.list().then((datas) => {
      const contactItems = datas.data.map((a) => ({
        ...{
          id: a.id,
          fullname: a.firstname + " " + a.lastname,
          email: a.email,
          telephone: a.telephone,
        },
      }));
      setContacts(contactItems);
    });

    UserService.list().then((items) => {
      const userItems = items.data.data.map((a) => ({
        ...{
          id: a.id,
          coach: a.name + " " + a.firstname,
          email: a.email,
          mobile_phone: a.mobile_phone,
          is_active: a.is_active,
        },
      }));
      setUsers(userItems);
    });
  }, [doUpdate, store]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header  sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Attribution Coach</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item">Gestion des NC</li>
              <li className="breadcrumb-item active">Attribution Coach</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="row my-2">
                  <div className="col-3">
                    <h5 className="text-31-primary">Liste des affectations </h5>
                  </div>
                  <div className=" offset-1 col-3">
                    <DashboardDialog />
                  </div>
                </div>
                <div className="row mr-1 ml-1">
                  <AffectactionTable
                    affectations={affectations}
                    users={users}
                    contacts={contacts}
                    sendUpdate={sendUpdate}
                    canEdit={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListAffectations;

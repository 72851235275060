import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "@mui/material";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useStore } from "react-redux";
import UserService from "../../services/user-service";
import Icon from "@mui/material/Icon";
import { Edit } from "@mui/icons-material";
import Select from "react-select"

const DialogModifierUser = (props) => {
  const store = useStore();

  const initialState = {
    id: props.user.id,
    name: props.user.name,
    firstname: props.user.firstname,
    is_active: props.user.is_active,
    genre: props.user.genre,
    email: props.user.email,
    mobile_phone: props.user.mobile_phone,
    password: "",
    is_profil_ok: props.user.is_profil_ok,
    is_password_ok: props.user.is_password_ok,
    group_id : props.user.group_id,
    keycloak_user_id : props.user.keycloak_user_id,
    profils: props.user?.profils?.filter(p => p !== null)
      .map(p => ({
        ...{
          group_id: p.group_id,
          label: p.profil_name,
          value: p.group_id,
          profil_name: p.profil_name
        },
      })),
  };
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(initialState);
  const [profils] = useState(
    store.getState().referentiel.profils
    .map(p => ({
      ...{
        group_id: p.id,
        profil_name: p.profil_name,
        label: p.profil_name,
        value: p.id,
      },
    })),
  );
  const [selectedProfil, setProfil] = useState();
  const [type, setType] = useState("password");
  const [iconClass, setIconClass] = useState("fa fa-eye");

  const handleModalClose = () => {
    setShowModal(false);
    // props.sendUpdate(true);
  };

  const handleModalShow = () => {
    setUser(initialState);
    let profil = profils.filter((p) => p.id === props.user.group_id);
    setProfil(profil[0]);
    setShowModal(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    const name = event.target.name;
    const value = event.target.value;
    let data = { ...user, [name]: value };
    setUser(data);
  };

  const handleProfilChange = e => {
    setUser({ ...user, profils: e })
  }

  const handleActive = (e) => {
    setUser({ ...user, is_active: e.target.checked });
  };

  const toggleEye = (e) => {
    e.preventDefault();
    if (iconClass === "fa fa-eye") {
      setType("text");
      setIconClass("fa fa-eye-slash");
    } else {
      setType("password");
      setIconClass("fa fa-eye");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    user.group_id = selectedProfil?.id;
    user.profil = selectedProfil?.profil_name;
    UserService.update(props.user.id, user)
      .then((response) => {
        toast.success(response.data.description); // , { autoClose: 3000 }
        props.sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.description); // , { autoClose: 3000 }
      });
    return;
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="warning"
        onClick={handleModalShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>
      <Modal show={showModal} onHide={handleModalShow}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary">
              <small>Modifier un utilisateur</small>
            </Modal.Title>
            <button
              onClick={handleModalClose}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col lg="3">
                <AvField
                  type="select"
                  id="genre"
                  name="genre"
                  value={user.genre}
                  onChange={handleChange}
                  className="form-control"
                  label="Genre"
                >
                  <option hidden>Genre</option>
                  <option value="Homme">Homme</option>
                  <option value="Femme">Femme</option>
                </AvField>
              </Col>
              <Col lg="6">
                <label>Profil</label>
                <Select
                  value={user?.profils}
                  defaultValue={user?.profils}
                  isMulti={true}
                  onChange={handleProfilChange}
                  options={profils}
                  name="profils"
                  id="profils"
                  closeMenuOnSelect={false}
                />
              </Col>
              <Col lg="3">
                <input
                  className="form-check-input"
                  name="is_active"
                  type="checkbox"
                  defaultChecked={user.is_active}
                  onChange={handleActive}
                  id="is_active"
                />
                <label className="form-check-label" htmlFor="is_active">
                  Activer
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="text"
                  className="form-control"
                  name="firstname"
                  id="firstname"
                  value={user.firstname}
                  onChange={handleChange}
                  label="Prénom"
                  errorMessage="Prénom obligatoire"
                  validate={{ required: true }}
                />
              </Col>
              <Col lg="6">
                <AvField
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  value={user.name}
                  onChange={handleChange}
                  label="Nom"
                  errorMessage="Nom obligatoire"
                  validate={{ required: true }}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  value={user.email}
                  onChange={handleChange}
                  label="Email (identifiant)"
                  errorMessage="Email obligatoire"
                  validate={{ required: true }}
                  disabled={true}
                />
              </Col>
              <Col lg="6">
                <AvField
                  type="text"
                  className="form-control"
                  name="mobile_phone"
                  id="mobile_phone"
                  value={user.mobile_phone}
                  onChange={handleChange}
                  label="Téléphone"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type={type}
                  className="form-control"
                  name="password"
                  id="password"
                  value={user.password}
                  onChange={handleChange}
                  label="Mot de passe"
                />
              </Col>
              <Col lg="2">
                <Icon
                  className={"mt-4 " + iconClass}
                  fontSize="string"
                  onClick={toggleEye}
                />
              </Col>
              <Col lg="4">&nbsp;</Col>
            </Row>
          </Modal.Body>
          <Modal.Body>
            <Row>
              <Col lg="1">&nbsp;</Col>
              <Col lg="3">
                <input
                  className="form-check-input"
                  name="is_profil_ok"
                  type="checkbox"
                  defaultChecked={user.is_profil_ok}
                  id="is_profil_ok"
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="is_profil_ok">
                  Profil OK
                </label>
              </Col>
              <Col lg="4">
                <input
                  className="form-check-input"
                  name="is_password_ok"
                  type="checkbox"
                  defaultChecked={user.is_password_ok}
                  id="is_password_ok"
                  disabled={true}
                />
                <label className="form-check-label" htmlFor="is_password_ok">
                  Mot de passe OK
                </label>
              </Col>
              <Col lg="3">
                &nbsp;
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="ml-1 mr-1"
            >
              Enregistrer
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleModalClose}
              className="ml-1 mr-1"
            >
              Fermer
            </Button>
          </Modal.Footer>
          <ToastContainer autoClose={3000} />
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default DialogModifierUser;

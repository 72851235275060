import api from "./api";

const list = async () => {
  return await api.get("/v1/session-bilans").then((response) => response.data);
};
const add = async (user) => {
  return await api.post("/v1/session-bilans", user).then((response) => response);
};
const remove = async id => {
  return await api.delete(`/v1/session-bilans/${id}`)
              .then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/session-bilans/${id}`)
              .then((response) => response);
};
const update = async (id, dateIn) => {
  return await api.put(`/v1/session-bilans/${id}`, dateIn)
              .then((response) => response);
};

const getListeBilans = async () => {
  return await api.get("/v1/bilans").then((response) => response.data);
};

const BilanService = {
  list,
  add,
  edit,
  update,
  remove,
  getListeBilans
};
export default BilanService;

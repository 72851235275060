import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useStore } from "react-redux";
import { useNavigate } from "react-router-dom";

const Callback = () => {
  const location = useLocation();
  const store = useStore();

  useEffect(() => {
    const exchangeCodeForToken = async (code) => {
        const tokenUrl = `${process.env.REACT_APP_KEYCLOAK_URL}/protocol/openid-connect/token`;
    
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', `${process.env.REACT_APP_CLIENT_ID}`);
        params.append('client_secret', `${process.env.REACT_APP_CLIENT_SECRET}`);  // Si nécessaire
        params.append('code', code);
        params.append('redirect_uri', `${process.env.REACT_APP_CLIENT_URL}/callback`);  // URL de redirection
    
        const response = await fetch(tokenUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: params,
        });
    
        const data = await response.json();

        if (data.access_token) {
            store.dispatch({ type: "LOGIN_SESSION", payload: data });
            // navigate("/home");
            window.location.href = '/home';
          
        } else {
            console.log('Échec de la récupération du token');
            // Rediriger vers le formulaire de connexion si nécessaire
            window.location.href = '/login';
        }
      };

    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');

    if (code) {
      // Échanger le code contre un token d'accès
      exchangeCodeForToken(code);
    } else {
      // Si pas de session SSO, rediriger vers le formulaire de login de app3
      window.location.href = '/login';
    }
  }, [location, store]);

  return <div>Authentification en cours...</div>;
};

export default Callback;


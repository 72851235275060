import React from 'react';
import { useMediaQuery } from 'react-responsive';

const ProgramCertif = ({ user }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isMiddleScreen = useMediaQuery({ query: '(max-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    return (
        <div className="position-relative mt-1" style={{ position: "relative", height: "" }}>
            {isBigScreen && 
                <><img src="https://prog31-front.jw-solutions.fr/assets/images/diplome-31-jours.png" className="img-fluid" alt="" />
            <h1 className="position-absolute" style={{
                position: "absolute",
                minHeight: "100vh",
                height: "100vh",
                top: '62vh', left: '35vw',
                fontSize: '2.5em', fontWeight: 'bold'
            }}>
                {user.firstname} {user.lastname.toUpperCase()}
            </h1>
            <div className="" style={{
                position: "absolute", height: "100vh",
                top: '94vh', left: '11vw',
                fontSize: '2.5em', fontWeight: 'bold'
            }}>
                <span className=''>Le Blanc-Mesnil, le {new Date(Date.now()).toLocaleString().slice(0, 3).match(/[0-9]/i) ? new Date(Date.now()).toLocaleString().split(' ')[0].split(',')[0] : new Date(Date.now()).toLocaleString().split(' ')[1] + " " + new Date(Date.now()).toLocaleString().split(' ')[2] + " " + new Date(Date.now()).toLocaleString().split(' ')[3]}</span>
            </div>
            </> }  
            {isMiddleScreen && 
                <><img src="https://prog31-front.jw-solutions.fr/assets/images/diplome-31-jours.png" className="img-fluid" alt="" />
            <h1 className="position-absolute" style={{
                position: "absolute",
                minHeight: "100vh",
                height: "100vh",
                top: '62vh', left: '35vw',
                fontSize: '1.5em', fontWeight: 'bold'
            }}>
                {user.firstname} {user.lastname.toUpperCase()}
            </h1>
            <div className="" style={{
                position: "absolute", height: "100vh",
                top: '94vh', left: '11vw',
                fontSize: '1.5em', fontWeight: 'bold'
            }}>
                <span className=''>Le Blanc-Mesnil, le {new Date(Date.now()).toLocaleString().slice(0, 3).match(/[0-9]/i) ? new Date(Date.now()).toLocaleString().split(' ')[0].split(',')[0] : new Date(Date.now()).toLocaleString().split(' ')[1] + " " + new Date(Date.now()).toLocaleString().split(' ')[2] + " " + new Date(Date.now()).toLocaleString().split(' ')[3]}</span>
            </div>
            </> } 
            {isTabletOrMobile && 
                <><img src="https://prog31-front.jw-solutions.fr/assets/images/diplome-31-jours.png" className="img-fluid" alt="" />
            <h1 className="position-absolute" style={{
                position: "absolute",
                minHeight: "100vh",
                height: "100vh",
                top: '32vh', left: '25vw',
                fontSize: '1.5em', fontWeight: 'bold'
            }}>
                {user.firstname} {user.lastname.toUpperCase()}
            </h1>
            <div className="" style={{
                position: "absolute", height: "100vh",
                top: '49vh', left: '9vw',
                fontSize: '1.5em', fontWeight: 'bold'
            }}>
                {/* <div className="position-absolute top-50 start-50 translate-middle" style={{minHeight: "100vh"}}> */}
                <span className=''>Le Blanc-Mesnil, le {new Date(Date.now()).toLocaleString().slice(0, 3).match(/[0-9]/i) ? new Date(Date.now()).toLocaleString().split(' ')[0].split(',')[0] : new Date(Date.now()).toLocaleString().split(' ')[1] + " " + new Date(Date.now()).toLocaleString().split(' ')[2] + " " + new Date(Date.now()).toLocaleString().split(' ')[3]}</span>
            </div>
            </> } 
        </div>
        
    )
}

export default ProgramCertif
//import http from "../http-common";
import api from "./api";

const getMesAffectations = async() => {
  return await api.get("/v1/mes-affectations").then((response) => response.data);
};

const listAppel = async() => {
  return await api.get("/v1/statut-appels").then((response) => response.data);
};
const add = async (user) => {
  return await api.post("/suivi-telephoniques", user).then((response) => response);
};
const remove = async id => {
  return await api.delete(`/v1/mes-affectations/${id}`)
              .then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/mes-affectations/${id}`)
              .then((response) => response);
};
const update = async (id, contact) => {
  return await api.put(`/v1/mes-affectations/${id}`, contact)
              .then((response) => response);
};

const getSources = async () => {
  return await api.get("/v1/source-contacts").then((response) => response.data);
};

const AffectationService = {
 listAppel,
 getMesAffectations,
 add,
 edit,
 update,
 remove,
 getSources
};
export default AffectationService;

import React from "react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { Edit } from "@mui/icons-material";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "react-bootstrap";
import { useStore } from "react-redux";


const EditSuiviAction = (props) => {
  const store = useStore();
  const initialState = {
    contact_id: "",
    contact: "",
    coach_appel: "",
    date_appel: "",
    statut_id: "",
    commentaires: "",
  };
  const [appels, setAppels] = useState(initialState);
  const [statuts, setStatut] = useState([]);
  const [actions] = useState(store.getState().referentiel.actions);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAppels(props.nc);
    setStatut(props.statuts);
  }, [props.nc, props.statuts]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAppels((appels) => ({ ...appels, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updateContact = { ...appels };
    updateContact.date_appel = moment(appels.date_appel).format("yyyy-MM-DD");
    updateContact.statut_id = appels.statut_id;
    updateContact.coach_id = JSON.parse(localStorage.getItem("userId"));
    setAppels(updateContact);
    SuiviTelephoniqueService.update(props.nc.id, appels)
      .then((data) => {
        toast.success("Compte rendu modifié !");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary">
              <small>Modifier un suivi de NC</small>
            </Modal.Title>
            <button
              onClick={handleClose}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="6">
                <AvField
                  type="text"
                  name="contact"
                  value={appels.contact_name}
                  label="Contact"
                  onChange={handleChange}
                  disabled={true}
                />
              </Col>
              <Col lg="6">
                <AvField
                  type="text"
                  className="form-control"
                  disabled={false}
                  name="coach_appel"
                  value={appels.coach_name}
                  label="Coach"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="date"
                  name="date_appel"
                  value={appels.date_appel}
                  disabled={true}
                  placeholder="Entrez la date d'appel"
                  label="Date d'appel"
                />
              </Col>
              <Col lg="6">
                <AvField
                  type="select"
                  className="form-control"
                  name="statut_id"
                  disabled={true}
                  value={appels?.statut_id}
                  onChange={handleChange}
                  label="Statut d'appel"
                >
                  {Array.isArray(statuts) &&
                    statuts.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.libelle}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField
                  type="select"
                  className="form-control"
                  id="statut_action_id"
                  name="statut_action_id"
                  value={appels?.statut_action_id}
                  onChange={handleChange}
                  label="Action"
                >
                  {Array.isArray(actions) &&
                    actions.map((a, index) => (
                      <option key={index} value={index}>
                        {a}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <AvField
                  type="textarea"
                  className="form-control"
                  id="commentaires"
                  rows="3"
                  name="commentaires"
                  onChange={handleChange}
                  label="Commentaires"
                  value={appels?.commentaires || ""}
                  errorMessage="Commentaires obligatoires !"
                  validate={{ required: true }}
                ></AvField>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="ml-1 mr-1"
            >
              Modifier
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleClose}
              className="ml-1 mr-1"
            >
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
}

export default EditSuiviAction;

import { configureStore } from "@reduxjs/toolkit";
import { getLocalItem, setLocalItem } from "../services/localstorage-service";
import { jwtDecode } from "jwt-decode";


let state = {
  layout: getLocalItem('layout'),
  session : getLocalItem('session'),
  referentiel : getLocalItem('referentiel')
};

const reducer = (currentState, action) => {
  switch (action.type) {
    case "TOGGLE_LAYOUT": {
      const layout = { ...currentState.layout, isFull: action.payload };
      return { ...currentState, layout };
    }
    case "LOGOUT_SESSION": {
      currentState = sessionDestroy(currentState);
      return { ...currentState };
    }
    case "LOGIN_SESSION": {
      console.log(action.payload);
      let user = jwtDecode(action.payload.accessToken ?? action.payload.access_token);
      const session = {
        ...currentState.session, 
        accessToken : action.payload.accessToken ?? action.payload.access_token, 
        refreshToken: action.payload.refreshToken ?? action.payload.refresh_token,
        fullname : user.given_name + " " + user.family_name,
        username : user.preferred_username,
        profils : user.resource_access[process.env.REACT_APP_API_ID]?.roles, 
        email : user.email,
        userId : action.payload.user_id 
      };
      const config = { ...currentState.config, initialized : false };
      return { ...currentState, session, config };
    }
    case "INIT_PROFILS" : {
      const referentiel = { ...currentState.referentiel, profils : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_BILANS" : {
      const referentiel = { ...currentState.referentiel, bilans : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_SECTEURS" : {
      const referentiel = { ...currentState.referentiel, secteurs : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_SOURCES" : {
      const referentiel = { ...currentState.referentiel, sources : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_LISTES" : {
      const referentiel = { ...currentState.referentiel, listes : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_STATUTS" : {
      const referentiel = { ...currentState.referentiel, statuts : action.payload }
      return { ...currentState, referentiel };
    }
    case "INIT_ACTIONS" : {
      const referentiel = { ...currentState.referentiel, actions : action.payload }
      console.log(action.payload);
      return { ...currentState, referentiel };
    }
    default:
      return currentState;
  }
};

const sessionDestroy = (state) => {
  setLocalItem('token', undefined);
  const session = undefined;
  const layout = undefined;
  const referentiel = undefined;
  return {...state, session, layout, referentiel};
}

export const store = configureStore({
  preloadedState: state,
  reducer,
});

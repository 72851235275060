// WithNav.js (Stand-alone Functional Component)
import React from 'react';
import { Outlet } from 'react-router';
import Nav from '../components/layouts/nav';

const WithNav = () => {
  return (
    <>
      <Nav />
      <Outlet />
    </>
  );
}

export default WithNav;
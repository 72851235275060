import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { ToastContainer } from "react-toastify";

const ListeFormation = () => {

  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });
  }, [store]);

  return (
    <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
      <div className="block-header sticky-header">
        <div className="row clearfix">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-31-secondary">Import de fichiers</h4>
          </div>
          <div className="col-md-6 col-sm-12 text-right">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">
                  <i className="icon-home"></i>
                </a>
              </li>
              <li className="breadcrumb-item active">Import</li>
              <li className="breadcrumb-item active">Import de fichiers</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card">
              <div className="body">
                <div className="table-responsive">
                <img
                  src={"../assets/images/under_construction.png"} width={295} height={242}
                  className={"user-photo"}
                  alt={"User Profile"}
                />
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListeFormation;

import api from "./api";

const login = async (email, password) => {
  return await api
    .post("/login", { email, password }, { withCredentials: true })
    .then(response => {
      return response.data;
    });
}

const logout = async () => {
  await api.post("/logout", {}, { withCredentials: true })
    .then(response => {  });
}

const profils = async () => {
  return await api.get("/profiles")
    .then(response => response.data);
}

const AuthenticationService = {
  login,
  logout,
  profils
};

export default AuthenticationService;

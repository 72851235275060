import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { useDemoData } from "@mui/x-data-grid-generator";
import ContactService from "../../services/contact-service";
import ListBilan from "../../pages/gestion-nc/list-bilan-session";
import { toast } from "react-toastify";
import DialogModificationNc from "../../pages/gestion-nc/dialog-modification-nc";
import AuthorizationService from "../../services/authorization-service";

const VISIBLE_FIELDS = [
  "id",
  "lastname",
  "firstname",
  "email",
  "telephone",
  "genre",
  "est_mineur",
  "birthdate",
  "author_id",
  "subscription_date",
  "created_at",
  "ville",
  "secteur_gf",
  "code_postal",
  "adresse_postale",
  "metier",
  "source",
  "source_id",
  "liste_id",
  "actions",
  "libelle_source",
];

const NcPrincipaleTable = ({ contacts, listes, secteurs, sources, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(5);

  // Ajout de l'état pour la visibilité des colonnes
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    subscription_date: false,
    genre: false,
    author: false,
    birthdate: false,
    metier: false,
    ville: false,
    code_postal: false,
    secteur_gf: false,
    adresse_postale: false,
  });

  const columns = [
    {
      field: "lastname",
      headerName: "Nom",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "firstname",
      headerName: "Prenom",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0,
      width: 100,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "telephone",
      headerName: "Telephone",
      flex: 0,
      width: 100,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "etat_mineur",
      headerName: "Ado/Adulte",
      flex: 0,
      width: 90,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "libelle_source",
      type: "",
      headerName: "Source",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "coach",
      headerName: "Coach",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "id",
      sort: "desc",
      headerName: "Id",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "author",
      headerName: "Saisi Par",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "subscription_date",
      headerName: "Date de saisie",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "genre",
      headerName: "Genre",
      flex: 1,
      hideable: true,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "birthdate",
      type: "",
      headerName: "Né le",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    {
      field: "metier",
      type: "",
      headerName: "Métier",
      flex: 1,
      headerClassName: "header-prog31",
      headerAlign: "center",
    },
    { field: "ville", headerClassName: "header-prog31", headerAlign: "center" },
    {
      field: "code_postal",
      headerClassName: "header-prog31",
      headerName: "Code postal",
      headerAlign: "center",
    },
    {
      field: "secteur_gf",
      headerClassName: "header-prog31",
      headerName: "Secteur GF",
      headerAlign: "center",
    },
    {
      field: "adresse_postale",
      headerClassName: "header-prog31",
      headerName: "Adresse postale",
      headerAlign: "center",
    },
    {
      field: "bilan",
      type: "actions",
      headerName: "Bilan",
      width: 70,
      headerClassName: "header-prog31",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <strong>
            <ListBilan cellValues={cellValues} sendUpdate={sendUpdate} />
          </strong>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "header-prog31",
      width: 140,
      renderCell: (id) => (
        <React.Fragment>
          <DialogModificationNc
            nc={id.row}
            id={id}
            listes={listes}
            sources={sources}
            secteurs={secteurs}
            sendUpdate={sendUpdate}
          />
          {AuthorizationService.writeListePrincipale() && (
            <Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                width: "40px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(id);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>
          )}
        </React.Fragment>
      ),
    },
  ];

  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 100,
    maxColumns: 6,
    visibleFields: VISIBLE_FIELDS,
  });

  const handleDelete = (contact) => {
    ContactService.remove(contact.id)
      .then((data) => {
        toast.success("Contact supprimé !", { autoClose: 2000 });
        sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.description, {
          autoClose: 3000,
          onClose: () => {}, // TODO, mise à jour des écrans
        });
      });
  };

  return (
    <div style={{ height: 500, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        {...data}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={contacts}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
        columnHeaderHeight={40}
      />
    </div>
  );
};

export default NcPrincipaleTable;

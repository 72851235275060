import api from "./api";

const getNbNcMax = async () => {
  return await api.get("/v1/configs").then((response) => response.data);
};

const getNcByCoach = async () => {
  return await api.get("/v1/user-quota").then((response) => response.data);
};

const updateNbNcMax = async (id, data) => {
  return await api.put(`/v1/configs/${id}`, data);
};



const ConfigService = {
  getNbNcMax,
  updateNbNcMax,
  getNcByCoach
};
export default ConfigService;

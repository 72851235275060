const getRefreshToken = () => {
  const session = JSON.parse(localStorage.getItem("session"));
  return session?.refreshToken;
}

const getAccessToken = () => {
  const session = JSON.parse(localStorage.getItem("session"));
  return session?.accessToken;
}

const updateAccessToken = (token) => {
  let session = JSON.parse(localStorage.getItem("session"));
  session.accessToken = token;
  localStorage.setItem("session", JSON.stringify(session));
}

const deleteAccessToken = () => {
  localStorage.removeItem("session");
  localStorage.removeItem("referentiel");
}


const TokenService = {
  getRefreshToken,
  getAccessToken,
  updateAccessToken,
  deleteAccessToken
};
export default TokenService;

import React, { useState, useEffect } from 'react';
import { useStore } from "react-redux";

import ContactService from '../services/contact-service';
import UserService from '../services/user-service';
import AuthenticationService from '../services/auth-service';
import SuiviTelephoniqueService from '../services/suivi-telephonique-service';
import BilanService from "../services/bilan-service";

const Home = () => {
  const store = useStore();
  const [nbContacts, setNbContacts] = useState(0);
  const [nbUsers, setNbUsers] = useState(0);
  const [layout, setLayout] = useState(store.getState().layout);
  const [referentiel, setReferentiel] = useState(store.getState().referentiel);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
      setReferentiel(store.getState().referentiel);
    });

    if(!referentiel) {
      AuthenticationService.profils().then((data) => {
        store.dispatch({ type: "INIT_PROFILS", payload: data });
      });
      BilanService.getListeBilans().then((response) => {
        store.dispatch({ type: "INIT_BILANS", payload: response.data });
      });
      ContactService.getSecteurs().then((response) => {
        store.dispatch({ type: "INIT_SECTEURS", payload: Object.values(response) });
      });
      ContactService.getSources().then((response) => {
        store.dispatch({ type: "INIT_SOURCES", payload: response.data });
      });
      ContactService.activeListTypes().then((response) => {
        store.dispatch({ type: "INIT_LISTES", payload: response.data });
      });
      SuiviTelephoniqueService.getStatutAppels().then((response) => {
        store.dispatch({ type: "INIT_STATUTS", payload: response.data });
      });
      SuiviTelephoniqueService.getStatutActions().then((response) => {
        store.dispatch({ type: "INIT_ACTIONS", payload: Object.values(response) });
      });
    }

    ContactService.list().then(data => { setNbContacts(data.data.length); });
    UserService.list().then(response => { 
      setNbUsers(response.data.data.length); 
    });
  }, [store, referentiel])
  return (
    <React.Fragment>
      <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>Tableau de bord</h2>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  {/* <Link to="/home">
                    <i className="icon-home"></i>
                  </Link> */}
                </li>
                <li className="breadcrumb-item active">Administration</li>
              </ul>
              {/* <Link to=""
                className="btn btn-sm btn-primary"
                title=""
              >
                Create New
              </Link> */}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-12">
              <div className="card top_report">
                <div className="row clearfix">
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="body">
                      <div className="clearfix">
                        <div className="float-left">
                          <i className="fa fa-2x fa-address-card  text-col-blue"></i>
                        </div>
                        <div className="number float-right text-right">
                          <h6>Nouveaux convertis</h6>
                          <span className="font700">{nbContacts}</span>
                        </div>
                      </div>
                      <div className="progress progress-xs progress-transparent custom-color-blue mb-0 mt-3">
                        <div
                          className="progress-bar"
                          data-transitiongoal="87"
                        ></div>
                      </div>
                      <small className="text-muted">
                        Nombre total de NC en 2022.
                      </small>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="body">
                      <div className="clearfix">
                        <div className="float-left">
                          <i className="fa fa-2x fa-graduation-cap text-col-green"></i>
                        </div>
                        <div className="number float-right text-right">
                          <h6>Nouveaux convertis Certifiés</h6>
                          <span className="font700">10</span>
                        </div>
                      </div>
                      <div className="progress progress-xs progress-transparent custom-color-green mb-0 mt-3">
                        <div
                          className="progress-bar"
                          data-transitiongoal="28"
                        ></div>
                      </div>
                      <small className="text-muted">
                        Nombre total de certifiés en 2022.
                      </small>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="body">
                      <div className="clearfix">
                        <div className="float-left">
                          <i className="fa fa-2x fa-certificate text-col-red"></i>
                        </div>
                        <div className="number float-right text-right">
                          <h6>Membres en formation</h6>
                          <span className="font700">21</span>
                        </div>
                      </div>
                      <div className="progress progress-xs progress-transparent custom-color-red mb-0 mt-3">
                        <div
                          className="progress-bar"
                          data-transitiongoal="41"
                        ></div>
                      </div>
                      <small className="text-muted">
                        Les formations.
                      </small>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="body">
                      <div className="clearfix">
                        <div className="float-left">
                          <i className="fa fa-2x fa-user-plus text-col-yellow"></i>
                        </div>
                        <div className="number float-right text-right">
                          <h6>Membres du département</h6>
                          <span className="font700">{nbUsers}</span>
                        </div>
                      </div>
                      <div className="progress progress-xs progress-transparent custom-color-yellow mb-0 mt-3">
                        <div
                          className="progress-bar"
                          data-transitiongoal="75"
                        ></div>
                      </div>
                      <small className="text-muted">
                        Le nombre des utilisateurs.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12">
              <div className="card">
                <div className="header bline">
                  <h2>Statistiques</h2>
                  <ul className="header-dropdown dropdown dropdown-animated scale-left">
                    <li>
                      {/* <Link to=""
                        className="btn btn-default btn-sm"
                        
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Monthly"
                      >
                        Monthly
                      </Link> */}
                    </li>
                    <li>
                      {/* <Link to=""
                        className="btn btn-outline-primary btn-sm"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Yearly"
                      >
                        Yearly
                      </Link> */}
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <div
                    id="User_Statistics"
                    className="mt-2"
                    style={{ height: "290px" }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="card">
                <div className="header">
                  {/* <h2></h2>
                  <ul className="header-dropdown dropdown dropdown-animated scale-left">
                    <li>
                      {" "}
                      <a
                        href="#"
                        data-toggle="cardloading"
                        data-loading-effect="pulse"
                      >
                        <i className="icon-refresh"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="full-screen">
                        <i className="icon-size-fullscreen"></i>
                      </a>
                    </li>
                    <li className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></a>
                      <ul className="dropdown-menu">
                        <li>
                          <a href="j#">Action</a>
                        </li>
                        <li>
                          <a href="#">Another Action</a>
                        </li>
                        <li>
                          <a href="#">Something else</a>
                        </li>
                      </ul>
                    </li>
                  </ul> */}
                </div>
                <div className="body">
                  {/* <div id="chart-top-products" className="chartist"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;

import React, { useState, useEffect } from "react";
import axios from "axios";
import ContactService from "../../services/contact-service";

const AdresseForm = ({ setCodePostal, handleReinitial, value }) => {
  const [ville, setVille] = useState(value || ""); // Initialiser avec la valeur passée par le parent

  // Utiliser useEffect pour mettre à jour `ville` lorsque la prop `value` change
  useEffect(() => {
    setVille(value);
  }, [value]);

  const handleVilleChange = (e) => {
    const ville = e.target.value;
    setVille(ville);

    // Requête de géocodage vers l'API Nominatim d'OpenStreetMap
    axios
      .get(
        `https://nominatim.openstreetmap.org/search?q=${ville}&format=json&addressdetails=1&limit=1`
      )
      .then((response) => {
        const data = response.data;
        if (data.length > 0) {
          const addressDetails = data[0].address;
          const codePostal = addressDetails.postcode || "Code postal non disponible";

          // Mise à jour du code postal et de la ville dans le parent via la fonction setCodePostal
          setCodePostal(codePostal, ville);

          // Appel au service ContactService pour obtenir le secteur en fonction du code postal
          ContactService.searchSecteur(codePostal).then((res) =>
            console.log("Secteur trouvé :", res)
          );
        } else {
          // Aucun résultat trouvé, réinitialisation des champs
          setCodePostal("", "");
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la requête API Nominatim :", error);
        setCodePostal("", ""); // Réinitialisation en cas d'erreur
      });
  };
  return (
    <div>
      <label htmlFor="ville">Ville</label>
      <input
        className="form-control"
        type="text"
        placeholder="Saisir la ville"
        value={ville} // Utilisation de l'état local ville contrôlé par le parent
        onChange={handleVilleChange}
      />
    </div>
  );
};

export default AdresseForm;

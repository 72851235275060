import React from 'react';
import { Navigate } from "react-router-dom";
import AuthorizationService from '../../services/authorization-service';

const PrivatedRoute = ({ children, requiredRoles }) => {
  const isAuthenticated = AuthorizationService.isAuthenticated();

  const hasRequiredRole = requiredRoles.map((role) => AuthorizationService.hasRequiredRole(role));
  return isAuthenticated && hasRequiredRole.includes(true) ? children : <Navigate to="/login" />;
};

export default PrivatedRoute;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WithoutNav from "../../helpers/without-nav";
import WithNav from "../../helpers/with-nav";
import Home from "../../pages/home";
import NotFound from "../../pages/not-found";
import Login from "../auth/login";
import Profile from "../auth/profile";
import ListUsers from "../../pages/users/list-users";
import ListNcPrincipale from "../../pages/gestion-nc/list-nc-principale";
import ListNcPerseverance from "../../pages/gestion-nc/list-nc-perseverance";
import ListeNcAttente from "../../pages/gestion-nc/list-nc-attente";
import Pointage from "../../pages/pointage/pointage";
import Certificat from "../../pages/certif/certificat";
import ListAffectations from "../../pages/affectations/list-affectations";
import PrivatedRoute from "./privated-route";
import Certificat2 from "../../pages/certif/certificat2";
import Perseverance from "../../pages/appel/list-perseverance";
import ListPrincipale from "../../pages/appel/list-principale";
import ListAction from "../../pages/appel/list-action";
import ListeMesSaisies from "../../pages/gestion-nc/list-mes-saisies";
import MonSuivi from "../../pages/appel/mon-suivi";
import MaListeAppel from "../../pages/appel/ma-list-appel";
import AttributionCoach from "../../pages/affectations/attribution-coach";
import ListeFormation from "../../pages/formation/list-formation";
import ListeProfils from "../../pages/profils/list-profils";
import UploadFichier from "../../pages/import/uploads-fichiers";
import Messagerie from "../../pages/messagerie/mailbox";
import Callback from "../auth/callback";

export function Header(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<WithoutNav />}>
          <Route index path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/callback" element={<Callback />} />
        </Route>
        <Route element={<WithNav />}>
          <Route
            path="/home"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_COACH_ADO,
                  process.env.REACT_APP_ROLE_COACH_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADO,
                  process.env.REACT_APP_ROLE_CERTIFICATION,
                  process.env.REACT_APP_ROLE_FORMATION,
                  process.env.REACT_APP_ROLE_POINTAGE,
                  process.env.REACT_APP_ROLE_SAISIE,
                  process.env.REACT_APP_ROLE_SUIVI_TEL,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                ]}
              >
                <Home />
              </PrivatedRoute>
            }
          />
          <Route
            path="/nc-liste-principale"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_POINTAGE,
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_CERTIFICATION,
                  process.env.REACT_APP_ROLE_COACH_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                ]}
              >
                <ListNcPrincipale />
              </PrivatedRoute>
            }
          />
          <Route
            path="/nc-liste-perseverance"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_POINTAGE,
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                ]}
              >
                <ListNcPerseverance />
              </PrivatedRoute>
            }
          />
          <Route
            path="/nc-liste-attente"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                ]}
              >
                <ListeNcAttente />
              </PrivatedRoute>
            }
          />
          <Route
            path="/nc-mes-saisies"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_SAISIE,
                ]}
              >
                <ListeMesSaisies />
              </PrivatedRoute>
            }
          />
          <Route
            path="/pointage-bilans"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_POINTAGE,
                ]}
              >
                <Pointage />
              </PrivatedRoute>
            }
          />
          <Route
            path="/formations"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_FORMATION,
                ]}
              >
                <ListeFormation />
              </PrivatedRoute>
            }
          />
          <Route
            path="/list-profils"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                ]}
              >
                <ListeProfils />
              </PrivatedRoute>
            }
          />
          <Route
            path="/imports"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                ]}
              >
                <UploadFichier />
              </PrivatedRoute>
            }
          />
          <Route
            path="/messagerie"
            element={
              <PrivatedRoute
              requiredRoles={[
                process.env.REACT_APP_ROLE_ADMIN,
                process.env.REACT_APP_ROLE_COACH_ADO,
                process.env.REACT_APP_ROLE_COACH_ADULTE,
                process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADULTE,
                process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADO,
                process.env.REACT_APP_ROLE_CERTIFICATION,
                process.env.REACT_APP_ROLE_FORMATION,
                process.env.REACT_APP_ROLE_POINTAGE,
                process.env.REACT_APP_ROLE_SAISIE,
                process.env.REACT_APP_ROLE_SUIVI_TEL,
                process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
              ]}
              >
                <Messagerie />
              </PrivatedRoute>
            }
          />
          <Route
            path="/list-users"
            element={
              <PrivatedRoute requiredRoles={[process.env.REACT_APP_ROLE_ADMIN]}>
                <ListUsers />
              </PrivatedRoute>
            }
          />
          <Route
            path="/attribution-list"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                ]}
              >
                <ListAffectations />
              </PrivatedRoute>
            }
          />
          <Route
            path="/attribution-coach"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                ]}
              >
                <AttributionCoach />
              </PrivatedRoute>
            }
          />
          <Route
            path="/certificats-reussite"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_CERTIFICATION,
                ]}
              >
                <Certificat />
              </PrivatedRoute>
            }
          />
          <Route
            path="/certif2"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_CERTIFICATION,
                ]}
              >
                <Certificat2 />
              </PrivatedRoute>
            }
          />
          <Route
            path="/suivi-perseverance"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADO,
                ]}
              >
                <Perseverance />
              </PrivatedRoute>
            }
          />
          <Route
            path="/suivi-principale"
            element={
              <PrivatedRoute requiredRoles={[process.env.REACT_APP_ROLE_ADMIN,
                process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                process.env.REACT_APP_ROLE_ATTRIBUTION_ADO
              ]}>
                <ListPrincipale />
              </PrivatedRoute>
            }
          />
          <Route
            path="/suivi-action"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE,
                  process.env.REACT_APP_ROLE_ATTRIBUTION_ADO,
                ]}
              >
                <ListAction />
              </PrivatedRoute>
            }
          />
          <Route
            path="/mon-suivi"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_COACH_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_ADO,
                ]}
              >
                <MonSuivi />
              </PrivatedRoute>
            }
          />
          <Route
            path="/ma-liste-appel"
            element={
              <PrivatedRoute
                requiredRoles={[
                  process.env.REACT_APP_ROLE_ADMIN,
                  process.env.REACT_APP_ROLE_COACH_ADULTE,
                  process.env.REACT_APP_ROLE_COACH_ADO,
                ]}
              >
                <MaListeAppel />
              </PrivatedRoute>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

import React from "react";
import { Provider } from "react-redux";
import { store } from "./stores/store";
import { setLocalItem } from "./services/localstorage-service";

import "./App.css";
import { Header } from "./components/layouts/header";
import "react-toastify/dist/ReactToastify.css";

store.subscribe(() => {
  setLocalItem('session', JSON.stringify(store.getState().session));
  setLocalItem('layout', JSON.stringify(store.getState().layout));
  setLocalItem('referentiel', JSON.stringify(store.getState().referentiel));
});

function App() {
  return (
    <Provider store={store}>
      <div>
        <Header />
      </div>
    </Provider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useStore } from "react-redux";

import { ToastContainer } from "react-toastify";
import SuiviTelephoniqueTable from "../../components/layouts/data-table-suivi-telephonique";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";

const ListPrincipale = () => {
  const initialState = [];
  const [contacts, setContacts] = useState(initialState);
  const [doUpdate, setDoUpdate] = useState(true);
  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);
  const [statuts] = useState(store.getState().referentiel.statuts);

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (doUpdate) {
      SuiviTelephoniqueService.getListePrincipale().then((datas) => {
        const items = datas.data
          .filter((a) => a !== null)
          .map((a) => ({
            ...{
              id: a.id,
              contact_name: `${a.contact.lastname} ${a.contact.firstname}`,
              date_appel: a.date_appel,
              statut_id: a.statut.id,
              statut_appel: a.statut.libelle,
              coach: `${a.coach.name} ${a.coach.firstname}`,
              contact: a.contact,
              commentaires: a.commentaires,
              coach_name: `${a.coach.firstname} ${a.coach.name}`
            },
          }));
        setContacts(items);
      });
      setDoUpdate(false);
    }
  }, [doUpdate, store]);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  return (
    <>
      <div id="main-content" className={layout?.isFull ? "layout-fullwidth" : ""}>
        <div className="block-header  sticky-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h4 className="text-31-secondary">Comptes Rendus Liste Principale</h4>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active">Suivi Téléphonique</li>
                <li className="breadcrumb-item active">Comptes rendus liste principale</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="table-responsive">
                    <SuiviTelephoniqueTable
                      contacts={contacts}
                      statuts={statuts}
                      sendUpdate={sendUpdate}
                    />
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListPrincipale;

import React from "react";
import { useState, useEffect } from "react";
import { useStore } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { Edit } from "@mui/icons-material";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "react-bootstrap";

const EditCompteRendu = (props) => {
  const initialState = {
    contact_id: "",
    contact_name: "",
    date_appel: "",
    statut_id: "",
    statut_appel: "",
    commentaires: "",
  };

  const store = useStore();
  const [appels, setAppels] = useState(initialState);
  const [statuts] = useState(store.getState().referentiel.statuts);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setAppels(props.nc);
  }, [props.nc]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAppels((appels) => ({ ...appels, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const updateContact = { ...appels };
    updateContact.contact_id = appels.contact_id;
    updateContact.date_appel = moment(appels.date_appel).format("yyyy-MM-DD");
    updateContact.statut_id = appels.statut_id;
    updateContact.coach_id = JSON.parse(localStorage.getItem("userId"));
    setAppels(updateContact);
    SuiviTelephoniqueService.update(props.nc.id, appels)
      .then((data) => {
        toast.success("Modification réussie!");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Problème observé lors de la modification!");
      });
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="warning"
        onClick={handleShow}
        className="m-1 text-white"
      >
        <Edit />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary"><small>Modifier un compte rendu</small></Modal.Title>
            <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="6">
                <AvField type="select" id="contact_id" className="form-control" disabled={true}
                  value={appels?.contact_id} label="Contact" name="contact_id">
                  <option value={appels?.contact_id}>{appels?.contact_name}</option>
                </AvField>
              </Col>
              <Col lg="6">
                <AvField type="text" className="form-control" disabled={true}
                  name="coach_appel" value={appels?.coach_name} label="Coach"
                />
            </Col>
            </Row>
            <Row>
              <Col lg="6">
                <AvField type="date" className="form-control" placeholder="Date de début" id="date_appel" disabled={true}
                  name="date_appel" value={appels?.date_appel} label="Date d'appel" />
              </Col>
              <Col lg="6">
                <AvField type="select" className="form-control" name="statut_id" value={appels?.statut_id}
                  label="Statut d'appel" onChange={handleChange}>
                  {Array.isArray(statuts) &&
                    statuts.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.libelle}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <AvField type="textarea" className="form-control" id="commentaires"
                  rows="3" name="commentaires" onChange={handleChange} label="Commentaires"
                  value={appels?.commentaires || ""}
                  errorMessage="Commentaires obligatoires !" validate={{ required: true }}
                ></AvField>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="primary" type="submit" className="ml-1 mr-1">
              Modifier
            </Button>
            <Button variant="contained" color="error" onClick={handleClose} className="ml-1 mr-1">
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default EditCompteRendu;

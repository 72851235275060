import React, { useState, useEffect } from "react";
import UserService from "../../services/user-service";
import DialogAjoutUser from "./dialog-ajout-user";
import DataTableUsers from "../../components/layouts/data-table-users";
import { useStore } from "react-redux";

const ListUsers = () => {
  const [users, setUsers] = useState([]);
  const store = useStore();
  const [layout, setLayout] = useState(store.getState().layout);
  const [doUpdate, setDoUpdate] = useState(true);

  const sendUpdate = (value) => {
    setDoUpdate(value);
  };

  useEffect(() => {
    store.subscribe(() => {
      setLayout(store.getState().layout);
    });

    if (doUpdate) {
      UserService.list().then((datas) => {
        const newArray = datas.data.data.map((a) => ({
          ...{
            id: a.id,
            fullname: `${a.firstname} ${a.name}`,
            name: a.name,
            firstname: a.firstname,
            email: a.email,
            genre: a.genre,
            mobile_phone: a.mobile_phone,
            is_active: a.is_active,
            actif : a.is_active ? "Oui" : "Non",
            is_valide:
              a.keycloak_user_id != null &&
              a.is_profil_ok &&
              a.is_password_ok
                ? "Oui"
                : "Non",
            profil: a.profils.map(p => p.profil_name).join(),
            is_password_ok : a.is_password_ok,
            is_profil_ok : a.is_profil_ok,
            group_id : a.group_id,
            keycloak_user_id : a.keycloak_user_id,
            profils: a?.profils?.filter(p => p !== null)
            .map(p => ({
              ...{
                group_id: p.group_id,
                profil_name: p.profil_name
              },
            })),

          },
        }));
        setUsers(newArray);
      });
      setDoUpdate(false);
    }
  }, [store, doUpdate]);

  return (
    <React.Fragment>
      <div
        id="main-content"
        className={layout?.isFull ? "layout-fullwidth" : ""}
      >
        <div className="block-header sticky-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h4 className="text-31-secondary">Liste des Utilisateurs</h4>
            </div>
            <div className="col-md-6 col-sm-12 text-right">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/home">
                    <i className="icon-home"></i>
                  </a>
                </li>
                <li className="breadcrumb-item active">Administration</li>
                <li className="breadcrumb-item active">Membres</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="table-responsive">
                    <div className="text-right mb-2">
                      <DialogAjoutUser sendUpdate={sendUpdate} />
                    </div>
                    <DataTableUsers users={users} sendUpdate={sendUpdate} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListUsers;

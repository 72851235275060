import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import SuiviContactService from "../../services/suivi-contacts-service";
import EditAffectation from "../../pages/affectations/edit-affectation";
import { toast } from "react-toastify";
import AuthorizationService from "../../services/authorization-service";

const AffectactionTable = ({
  affectations,
  users,
  contacts,
  sendUpdate,
  canEdit,
}) => {
  const [pageSize, setPageSize] = React.useState(5);

  // Utilisation du modèle de visibilité pour masquer certaines colonnes
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    contact_id: false,
    coach_id: false,
    created_at: false,
    subscription_date: false,
  });

  // Définition des colonnes avec utilisation correcte du modèle et de la propriété hide
  const columns = [
    {
      field: "id",
      headerName: "Id",
      hide: true, // Masquée par défaut
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "contact_id",
      headerName: "ContactId",
      hide: true, // Masquée par défaut
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "coach_id",
      headerName: "CoachId",
      hide: true, // Masquée par défaut
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "contact_name",
      headerName: "Contact",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "coach",
      headerName: "Coach",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "subscription_date",
      headerName: "Date de saisie",
      hide: true, // Masquée par défaut
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "created_at",
      headerName: "Créé le",
      hide: true, // Masquée par défaut
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "date_debut",
      headerName: "Date début",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "date_fin",
      headerName: "Date fin",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "commentaires",
      headerName: "Commentaires",
      flex: 1,
      headerClassName: "header-prog31",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "header-prog31",
      hide: !canEdit, // Si canEdit est faux, masquer les actions
      renderCell: (id) => (
        <React.Fragment>
          <EditAffectation
            affectation={id.row}
            id={id}
            show={true}
            users={users}
            contacts={contacts}
            sendUpdate={sendUpdate}
          />
          {AuthorizationService.writeAttribution() && (
            <Button
              id="deleteBtn"
              style={{
                backgroundColor: "#e8605d",
                color: "#fff",
                padding: "7px 7px",
              }}
              onClick={() => {
                if (
                  window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
                  )
                ) {
                  handleDelete(id);
                }
              }}
              variant="contained"
              color="primary"
              type="submit"
            >
              <DeleteIcon />
            </Button>
          )}
        </React.Fragment>
      ),
    },
  ];

  const handleDelete = (user) => {
    SuiviContactService.remove(user.id)
      .then((data) => {
        toast.success("Affectation supprimée !", { autoClose: 2000 });
        sendUpdate(true);
      })
      .catch((e) => {
        toast.error(e.response.data.description, {
          autoClose: 3000,
          onClose: () => {}, // TODO, mise à jour des écrans
        });
      });
  };

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={affectations}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel} // Utilisation du modèle de visibilité ici
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
        components={{ Toolbar: GridToolbar }}
      />
    </div>
  );
};

export default AffectactionTable;

import React from "react";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import DialogModifierUser from "../../pages/users/dialog-modification-user";

const DataTableUsers = ({ users, sendUpdate }) => {
  const [pageSize, setPageSize] = React.useState(10);

  // Modèle de visibilité des colonnes
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false, // Cacher la colonne ID
    genre: false, // Masquer la colonne Genre
  });

  // Définition des colonnes du tableau
  const columns = [
    {
      field: "id",
      sort: "desc",
      hide: true, // Cacher la colonne ID
      headerName: "Id",
      flex: 0,
      headerClassName: "header-prog31",
      width: 40,
    },
    {
      field: "fullname",
      headerName: "Nom",
      flex: 0,
      headerClassName: "header-prog31",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0,
      headerClassName: "header-prog31",
      width: 230,
    },
    {
      field: "mobile_phone",
      headerName: "Téléphone",
      flex: 0,
      headerClassName: "header-prog31",
      width: 150,
    },
    {
      field: "profil",
      headerName: "Profil",
      flex: 2,
      headerClassName: "header-prog31",
    },
    {
      field: "genre",
      hide: true, // Masquer la colonne Genre
      headerName: "Genre",
      flex: 1,
      hideable: true,
      headerClassName: "header-prog31",
    },
    {
      field: "actif",
      headerName: "Activé ?",
      flex: 0,
      width: 80,
      headerClassName: "header-prog31",
    },
    {
      field: "is_valide",
      headerName: "Valide ?",
      flex: 0,
      width: 70,
      headerClassName: "header-prog31",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 250,
      headerClassName: "header-prog31",
      renderCell: (params) => (
        <React.Fragment>
          <DialogModifierUser user={params.row} id={params} sendUpdate={sendUpdate} />
        </React.Fragment>
      ),
    },
  ];

  return (
    <div style={{ height: 450, width: "100%" }}>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        components={{ Toolbar: GridToolbar }}
        rows={users}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel} // Application du modèle de visibilité des colonnes
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        checkboxSelection
        rowHeight={40}
        columnHeaderHeight={40}
      />
    </div>
  );
};

export default DataTableUsers;

import React from 'react';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import AffectationService from "../../services/affectation-service";
import SuiviTelephoniqueService from "../../services/suivi-telephonique-service";
import moment from "moment";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Col, Row } from "react-bootstrap";
import { useStore } from "react-redux";

const AddAppel = (props) => {
  const initialState = {
    contact_id: "",
    contact: "",
    coach_appel: "",
    date_appel: "",
    statut_id: "",
    commentaires: "",
  };
  const store = useStore();
  const [appels, setAppels] = useState(initialState);
  const [affectations, setAffectations] = useState([]);
  const [statuts] = useState(store.getState().referentiel.statuts);
  const [session] = useState(store.getState().session);
  const [show, setShow] = useState(false);
  useEffect(() => {
    AffectationService.getMesAffectations().then((datas) => {
      setAffectations(datas.data);
    });
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setAppels(initialState)
    setShow(true);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAppels((appels) => ({ ...appels, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newContacts = { ...appels };
    newContacts.date_appel = moment(appels.date_appel).format("yyyy-MM-DD");
    newContacts.coach_id = session.userId;
    setAppels(newContacts);
    SuiviTelephoniqueService.add(newContacts)
      .then((data) => {
        console.log(data);
        toast.success("Compte rendu d'appel ajouté");
        props.sendUpdate(true);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <Button variant="contained" color="primary" onClick={handleShow}>
        <i className="fa fa-plus mt-1">&nbsp;</i>
        Ajouter
      </Button>

      <Modal show={show} onHide={handleClose}>
        <AvForm onValidSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title className="text-31-secondary"><small>Nouveau compte rendu d'appel</small></Modal.Title>
            <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg="6">
                <AvField type="select" id="contact_id" className="form-control" onChange={handleChange}
                  value={appels?.contact_id} label="Contact" name="contact_id"
                  errorMessage="Contact obligatoire !" validate={{ required: true }}>
                  <option hidden>Choisir un contact...</option>
                  {affectations &&
                    affectations.map((c) => (
                      <option key={c.contact.id} value={c.contact.id}>
                        {c.contact.firstname} {c.contact.lastname}
                      </option>
                    ))}
                </AvField>
              </Col>
              <Col lg="6">
                <AvField type="text" className="form-control" disabled={true}
                  name="coach_appel" value={affectations[0]?.coach.name} label="Coach"
                />
              </Col>
            </Row>
            <Row>
            <Col lg="6">
            <AvField type="date" className="form-control" placeholder="Date de début" id="date_appel"
                  name="date_appel" value={appels?.date_appel} label="Date d'appel" onChange={handleChange}
                  errorMessage="Date d'appel obligatoire !" validate={{ required: true }} />
              </Col> 
              <Col lg="6">
              <AvField type="select" className="form-control" name="statut_id" value={appels?.statut_id}
                  label="Statut d'appel" onChange={handleChange}
                  errorMessage="Statut d'appel obligatoire !" validate={{ required: true }} >
                    <option hidden>Choisir un statut...</option>
                  {Array.isArray(statuts) &&
                    statuts.map((s) => (
                      <option key={s.id} value={s.id}>
                        {s.libelle}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <AvField type="textarea" className="form-control" id="commentaires"
                  rows="3" name="commentaires" onChange={handleChange} label="Commentaires"
                  value={appels?.commentaires || ""}
                  errorMessage="Commentaires obligatoires !" validate={{ required: true }}
                ></AvField>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="primary" type="submit" className="ml-1 mr-1">
              Ajouter
            </Button>
            <Button variant="contained" color="error" onClick={handleClose} className="ml-1 mr-1">
              Fermer
            </Button>
          </Modal.Footer>
        </AvForm>
      </Modal>
    </React.Fragment>
  );
};

export default AddAppel;

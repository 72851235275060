//import http from "../http-common";
import api from "./api";
import UserService from "./user-service";

const getListePrincipale = async () => {
  return await api.get("/v1/suivi-telephonique-principale").then((response) => response.data);
};

const list = async () => {
  return await api
    .get("/v1/suivi-telephoniques")
    .then((response) => response.data);
};

const monSuivi = async () => {
  return await api
    .get("/v1/mon-suivi-telephonique")
    .then((response) => response.data);
};

const getListePerseverance = async () => {
  if (UserService.isRoleAdmin()) {
    return await api.get("/v1/suivi-telephonique-perseverance").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdo() || UserService.isRoleCoachPerseveranceAdo()) {
    return await api.get("/v1/suivi-telephonique-perseverance-ado").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdulte() || UserService.isRoleCoachPerseveranceAdulte()) {
    return await api.get("/v1/suivi-telephonique-perseverance-adulte").then((response) => response.data);
  }
  return [];
};

const getListeAction = async () => {
  if (UserService.isRoleAdmin()) {
    return await api.get("/v1/suivi-telephonique-action").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdo()) {
    return await api.get("/v1/suivi-telephonique-action-ado").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdulte()) {
    return await api.get("/v1/suivi-telephonique-action-adulte").then((response) => response.data);
  }
  return [];
};

const getStatutAppels = async () => {
  return await api.get("/v1/statut-appels").then((response) => response.data);
};

const getStatutActions = async () => {
  return await api.get("/v1/suivi-telephonique-liste-actions").then((response) => response.data);
};

const add = async (user) => {
  return await api
    .post("/v1/suivi-telephoniques", user)
    .then((response) => response);
};

const remove = async (id) => {
  return await api
    .delete(`/v1/suivi-telephoniques/${id}`)
    .then((response) => response);
};

const edit = async (id) => {
  return await api
    .get(`/v1/suivi-telephoniques/${id}`)
    .then((response) => response);
};
const update = async (id, contact) => {
  return await api
    .put(`/v1/suivi-telephoniques/${id}`, contact)
    .then((response) => response);
};

const getSources = async () => {
  return await api.get("/v1/source-contacts").then((response) => response.data);
};

const SuiviTelephoniqueService = {
  getStatutAppels,
  getStatutActions,
  getListePerseverance,
  getListePrincipale,
  getListeAction,
  monSuivi,
  list,
  add,
  edit,
  update,
  remove,
  getSources,
};
export default SuiviTelephoniqueService;

import api from "./api";
import axios from "axios";

// Récupération de la session de l'utilisateur stockée dans localStorage
const getSession = () => {
  return JSON.parse(localStorage.getItem('session'));
};

const isAuthenticated = () => {
  let session = getSession();
  return session ? true : false;
}

const hasRequiredRole = (role) => {
  let session = getSession();
  return session?.profils?.some(v => v === role);
}

// Vérification des rôles à partir de la session
const isRoleAdmin = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_ADMIN);
};

const isRoleCoachAdo = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_COACH_ADO);
};

const isRoleCoachAdulte = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_COACH_ADULTE);
};

const isRoleCoachPerseveranceAdulte = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADULTE);
};

const isRoleCoachPerseveranceAdo = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_COACH_PERSEVERANCE_ADO);
};

const isRoleCertification = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_CERTIFICATION);
};

const isRoleFormation = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_FORMATION);
};

const isRolePointage = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_POINTAGE);
};

const isRoleSaisie = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_SAISIE);
};

const isRoleAttributionAdo = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_ATTRIBUTION_ADO);
};

const isRoleAttributionAdulte = () => {
  let session = getSession();
  return session?.profils?.some(v => v === process.env.REACT_APP_ROLE_ATTRIBUTION_ADULTE);
};

// Fonctions API pour gérer les utilisateurs
const list = async () => {
  return await api.get("/v1/users").then((response) => response);
};

const add = async (user) => {
  return await api.post("/v1/users", user).then((response) => response);
};

const remove = async (id) => {
  return await api.delete(`/v1/users/${id}`).then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/users/${id}`).then((response) => response);
};

const update = async (id, user) => {
  return await api.put(`/v1/users/${id}`, user).then((response) => response);
};

const getCodePostalByTown = async (ville) => {
  await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
    params: {
      address: ville,
      key: 'Votre_Cle_API_Google_Maps', // Remplacez par votre clé d'API Google Maps
    },
  })
    .then((response) => response.data.results)
    .catch((error) => {
      console.error('Erreur de géocodage :', error);
    });
};

// Exportation du service utilisateur avec toutes les fonctions définies
const UserService = {
  list,
  add,
  edit,
  update,
  remove,
  getCodePostalByTown,
  hasRequiredRole,
  isAuthenticated,
  isRoleAdmin,
  isRoleCertification,
  isRoleCoachPerseveranceAdulte,
  isRoleCoachPerseveranceAdo,
  isRoleFormation,
  isRoleSaisie,
  isRoleCoachAdo,
  isRoleCoachAdulte,
  isRolePointage,
  isRoleAttributionAdo,
  isRoleAttributionAdulte,
};

export default UserService;

import api from "./api";

const add = async (nums) => {
  return await api.post("/v1/print-diplomes", nums, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token').accessToken
    }
  }).then((response) => response);
};


const edit = (id) => {
  return api.get(`/v1/print-diplomes/${id}`)
              .then((response) => response);
};
const update = (id, user) => {
  return api.put(`/v1/print-diplomes/${id}`, user)
              .then((response) => console.log(response));
};


const updates = (id, user) => {
  return api.post(`/v1/diplomes/update-date/${id}`, user)
              .then((response) => console.log(response));
};
const CertificatService = {
  add,
  edit,
  update,
  updates
};
export default CertificatService;

import api from "./api";
import UserService from "./user-service";

const list = async () => {
  return await api.get("/v1/contacts").then((response) => response.data);
};

const add = async (user) => {
  return await api.post("/v1/contacts", user).then((response) => response);
};
const remove = async (id) => {
  return await api.delete(`/v1/contacts/${id}`).then((response) => response);
};

const edit = async (id) => {
  return await api.get(`/v1/contacts/${id}`).then((response) => response);
};
const update = async (id, user) => {
  return await api
    .put(`/v1/contacts/${id}`, user)
    .then((response) => console.log(response));
};

const getListePrincipale = async () => {
  if (UserService.isRoleAdmin() || UserService.isRoleCertification() || UserService.isRolePointage()) {
    return await api.get("/v1/contact-principale").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdo() || UserService.isRoleCoachAdo()) {
    return await api.get("/v1/contact-principale-ado").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdulte() || UserService.isRoleCoachAdulte()) {
    return await api.get("/v1/contact-principale-adulte").then((response) => response.data);
  }
  return [];
};

const getListeAttente = async () => {
  if (UserService.isRoleAdmin()) {
    return await api.get("/v1/contact-attente").then((response) => response.data);
  } 
  if(UserService.isRoleAttributionAdulte()) {
    return await api.get("/v1/contact-attente-adulte").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdo()) {
    return await api.get("/v1/contact-attente-ado").then((response) => response.data);
  }
  return [];
};

const getListePerseverance = async () => {
  if (UserService.isRoleAdmin() || UserService.isRolePointage()) {
    return await api.get("/v1/contact-perseverance").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdo() || UserService.isRoleCoachPerseveranceAdo()) {
    return await api.get("/v1/contact-perseverance-ado").then((response) => response.data);
  }
  if(UserService.isRoleAttributionAdulte() || UserService.isRoleCoachPerseveranceAdulte()) {
    return await api.get("/v1/contact-perseverance-adulte").then((response) => response.data);
  }
  return [];
};

const getMesSaisies = async () => {
  return await api.get("/v1/contact-mes-saisies").then((response) => response.data);
};

const search = async (pattern) => {
  return await api
    .post("/v1/contact-find", { pattern: pattern })
    .then((response) => response);
};

const searchSecteur = async (pattern) => {
  return await api
    .post("/v1/contact-find-secteur/" + pattern)
    .then((response) => response);
};

const listTypes = async () => {
  return await api
    .get("/v1/contact-list-types")
    .then((response) => response.data);
};

const activeListTypes = async () => {
  return await api
    .get("/v1/contact-list-types-active")
    .then((response) => response.data);
};

const getSecteurs = async () => {
  return await api
    .get("/v1/contact-secteurs-gf")
    .then((response) => response.data);
};

const getSources = async () => {
  return await api.get("/v1/source-contacts").then((response) => response.data);
};

const ContactService = {
  list,
  add,
  edit,
  search,
  searchSecteur,
  update,
  remove,
  activeListTypes,
  listTypes,
  getSources,
  getSecteurs,
  getListePrincipale,
  getListeAttente,
  getListePerseverance,
  getMesSaisies,
};
export default ContactService;

import api from "./api";

const list = async () => {
  return await api.get("/v1/suivi-contacts").then((response) => response.data);
};
const add = async (user) => {
  return await api.post("/v1/suivi-contacts", user).then((response) => response);
};
const remove = async id => {
  return await api.delete(`/v1/suivi-contacts/${id}`)
              .then((response) => response);
};

const edit = async(id) => {
  return await api.get(`/v1/suivi-contacts/${id}`)
              .then((response) => response);
};
const update = async (id, user) => {
  return await api.put(`/v1/suivi-contacts/${id}`, user)
              .then((response) => console.log(response));
};

const SuiviContactService = {
  list,
  add,
  edit,
  update,
  remove
};
export default SuiviContactService;

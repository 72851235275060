import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useStore } from "react-redux";
import AuthenticationService from "../../services/auth-service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const store = useStore();

  const initialState = {
    email: "",
    password: "",
  };
  const [user, setUser] = useState(initialState);
  let [loading, setLoading] = useState(false);
  let [color] = useState("#ffffff");

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthenticationService.login(user.email, user.password)
      .then((data) => {
        toast("Connexion réussie!");
        console.log("test");
        store.dispatch({ type: "LOGIN_SESSION", payload: data });
        window.location.href = "/home";
         // window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        toast(err.response.data.message);
        setLoading(false);
      })
  };
  return (
    <div id="wrapper" className="auth-main">
      <div className="container" style={{ marginTop: "30vh" }}>
        <div className="row clearfix">
          <div className="col-lg-8">
            {loading && (
              <div className="">
                <ClipLoader
                  color={color}
                  loading={loading}
                  cssOverride={override}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
            <div className="auth_detail">
              <div className="card mb-3 logo_div d-flex">
                <div className="row g-0">
                  <div className="col-md-3 col-2 pr-0">
                    <img
                      className="img-fluid"
                      src="assets/images/logos/logo_31jours.png"
                      alt="Logo 31 Jours"
                    />
                  </div>
                  <div className="col-md-9 pl-0 pt-4 pb-4">
                    <div className="card-body p-0 col-8">
                      <h1 className="card-title text-monospace text-31">
                        P31 Jours'App
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="text-monospace text-31-secondary">
                <div
                  id="carouselExampleControls"
                  className="carousel vert slide"
                  data-ride="carousel"
                  data-interval="1500"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      Gestion des contacts
                    </div>
                    <div className="carousel-item">Suivi téléphonique</div>
                    <div className="carousel-item">Bilans Hebdomadaires</div>
                    <div className="carousel-item">Plannings</div>
                    <div className="carousel-item">Formations</div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="header">
                <p className="lead text-31">Connexion à votre compte</p>
              </div>
              <div className="body">
                <form
                  className="form-auth-small"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <label
                      htmlFor="signin-email"
                      className="control-label sr-only"
                    >
                      Email adresse
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="signin-email"
                      value={user.email}
                      placeholder="user@domain.com"
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="signin-password"
                      className="control-label sr-only"
                    >
                      Mot de passe
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="signin-password"
                      value={user.password}
                      placeholder="Mot de passe ..."
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group clearfix">
                    {/* <label className="fancy-checkbox element-left">
                      <input type="checkbox" />
                      <span>Se rappeler</span>
                    </label> */}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-nt-primary btn-lg btn-block"
                  >
                    SE CONNECTER
                  </button>
                  <div className="bottom">
                    {/* <span className="helper-text m-b-10 text-31">
                      <i className="fa fa-lock"></i>
                      <a href="#" className="text-31">
                        {" "}
                        Mot de passe oublié?
                      </a>
                    </span> */}
                    {/* <span className="text-31">
                      Je n'ai pas de compte?{" "}
                      <NavLink to="/register" className="text-31">S'inscrire</NavLink>
                      {/* <a href="page-register.html">S'inscrire</a> 
                    </span> */}
                  </div>
                  <ToastContainer />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;

import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const NotFound = () => {
  const navigate = useNavigate();
  
  return (
    <div id="wrapper" className="auth-main">
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-8">
            <div className="auth_detail">
              <div className="card mb-3 logo_div">
                <div className="row g-0">
                  <div className="col-md-3 pr-0">
                    <img
                      className="img-fluid"
                      src="assets/images/logos/logo_31jours.png"
                      alt="Logo 31 Jours"
                    />
                  </div>
                  <div className="col-md-9 pl-0 pt-4 pb-4">
                    <div className="card-body p-0">
                      <h1 className="card-title text-monospace text-31">
                        P31 Jours'App
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className="text-monospace text-31-secondary">
                <div
                  id="carouselExampleControls"
                  className="carousel vert slide"
                  data-ride="carousel"
                  data-interval="1500"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                       Gestion des contacts
                    </div>
                    <div className="carousel-item">Suivi téléphonique</div>
                    <div className="carousel-item">Bilans Hebdomadaire</div>
                    <div className="carousel-item">Plannings</div>
                    <div className="carousel-item">Formations</div>
                  </div>
                </div>
              </h2>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="header">
                {/* <p className="lead text-31">Connexion à votre compte</p> */}
                <h3>
                  <span className="clearfix title">
                    <span className="number left">404 </span>
                    <span className="text">
                      Oops! <br />
                      Page Not Found
                    </span>
                  </span>
                </h3>
              </div>
              <div className="body">
                <p>
                  La page recherchée est actuellement indisponible, veuillez contacter l'administrateur.
                </p>
                <div className="margin-top-30 mb-1">
                <button className="btn btn-default btn-block" onClick={() => navigate(-1)}>
                <i className="fa fa-arrow-left"></i> <span>Go Back</span>
                </button>
                    
                  <NavLink to="/home">
                    <button className="btn btn-primary btn-block">
                      <i className="fa fa-home"></i> <span>Accueil</span>
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
